export default class PointIndividualDirectInputModel {
  constructor(){
    this.objRaw = {};

    this.direct_input_item = {
      charge_amount: null,
      phone_number: '',
      transaction_name: '',
    };
    this.direct_input_list = [
      Object.assign({},this.direct_input_item)
    ];
  }
  getLoadData(){
    const commaRemoveList = this.direct_input_list.map(item => {
      const rObj = Object.assign({},item);
      rObj.charge_amount = Number(item.charge_amount.replaceAll(',',''));
      rObj.phone_number = item.phone_number.replaceAll('-','');
      return rObj;
    });
    let obj = {
      body: {
        transaction_list: commaRemoveList,
      },
      header: {
        request_at: new Date()
      }
    }
    return obj;
  }
}