export default class LoadModel {
  constructor(){
    this.objRaw = {};
    this.sheet_name = '';
    this.point_reward_count = '';
  }
  getLoadData(start_position, end_position, header_row_count){
    let obj = {
      body:{
        sheet_name: this.sheet_name,
        start_position: start_position,
        end_position: `${end_position}${Number(this.point_reward_count) + header_row_count}`,
      },
      header:{
        request_at: new Date()
      }
    }
    return obj;
  }
}