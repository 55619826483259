export default class TransactionModel {
  constructor(){
    this.objRaw = {};

    this.is_get_transaction_data = false;
    this.fail_count = 0;
    this.success_count = 0;
    this.transaction_id = '';
    this.transaction_list = [];
  }
  setTransactionData(obj){
    if( obj ) this.objRaw = obj;
    const { fail_count, success_count, transaction_id, transaction_list } = obj;
    this.is_get_transaction_data = true;
    this.fail_count = fail_count;
    this.success_count = success_count;
    this.transaction_id = transaction_id;
    this.transaction_list = transaction_list;
  }
  setConfirmData(obj){
    const confirm_list =  obj.transaction_list;
    let current_list = [];
    current_list = current_list.concat(this.transaction_list);
    this.transaction_list = current_list.map((transactionItem) => {
      const confirmFindedItem = confirm_list.find((confirmItem) => confirmItem.uid === transactionItem.uid);
      return confirmFindedItem ? confirmFindedItem : transactionItem;
    })
  }
  getConfirmData(){
    let obj = {
      body:{
        transaction_id : this.transaction_id,
      },
      header:{
        request_at: new Date()
      }
    }
    return obj;
  }
  getConfirmDataId(){
    return this.transaction_id;
  }
}