
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import PointIndividualDirectInputModel from '@/views/service/point/model/PointIndividualDirectInputModel';
import LoadModel from '@/views/service/point/model/LoadModel';
import TransactionModel from '@/views/service/point/model/TransactionModel';

export default class PointIndividualRewardViewModel {
  constructor() {
    this.tabData = {
      dataList: [
        { id: 'DIRECT_INPUT', text: '직접 입력' },
        { id: 'EXCEL_UPLOAD', text: '엑셀 업로드' },
      ],
      option: {
        isCount: false,
      },
      value: 'DIRECT_INPUT',
    };
    this.directInputModel = new PointIndividualDirectInputModel();
    this.excelLoadModel = new LoadModel();
    this.transactionModel = new TransactionModel();
    this.isModify = false;
    this.loadValid = { 
      sheet_name:{
        value: 'sheet_name',
        valueType: '',
        isValid: false,
        validAlertDesc: '구글 시트 이름을 입력해주세요',
      },
      point_reward_count:{
        value: 'point_reward_count',
        valueType: 'number',
        isValid: false,
        validAlertDesc: '숫자만 입력해주세요',
      },
    }
    this.boardData = {
      title:'구글시트 데이터',
      drawDataList: [
        {
          title:'지급할<br>포인트',
          width:'64px',
          value:'amount',
          filter: { name:'convertNumberToComma', value:false, value2:'-', },
          isAlignLeft: true,
        },
        {
          title:'퍼핀 회원 UID',
          width:'200px',
          value:'firfin_user_uid',
          isAlignLeft: true,
          emptyValueText:'-'
        },
        {
          title:'회원 이름',
          width:'74px',
          value:'user_name',
          isAlignLeft: true,
          emptyValueText:'-'
        },
        {
          title:'회원 전화번호',
          width:'120px',
          value:'user_phone_number',
          isAlignLeft: true,
          filter: {
            name:'convertPhoneNember',
          },
          emptyValueText:'-'
        },
        {
          title:'포인트 이름',
          width:'150px',
          value:'transaction_name',
          isAlignLeft: true,
        },
        // {
        //   title:'알림대상',
        //   width:'80px',
        //   value:'notification_target',
        //   isAlignLeft: true,
        // },
        {
          title:'상태',
          width:'90px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'point_transaction_status'
          },
          class: {
            classColorFilter:{
              name:'convertIdToColor',
              dataListName:'point_transaction_status',
              prev:'tc'
            },
          },
          isAlignLeft: true,
        },
        {
          title:'로드실패사유',
          width:'300px',
          value:'fail_reason',
          isAlignLeft: true,
          class:{
            name: 'tc_red'
          },
        },
      ],
      option:{
        isTotal: false,
        isSize: false,
        rowIdValue: 'uid'
      },
    };
    this.isLoaded = false;
    this.isConfirming = false;
    this.isConfirmed = false;
    this.isLoading = false;
  }

  onUpdateTab(){
    this.transactionModel = new TransactionModel();
  }

  // 직접 입력
  addDirectInputItem(){
    const itemData = Object.assign({},this.directInputModel.direct_input_item);
    this.directInputModel.direct_input_list.push(itemData);
  }
  removeDirectInputItem(index){
    if (this.directInputModel.direct_input_list.length === 1) {
      const itemData = Object.assign({},this.directInputModel.direct_input_item);
      this.directInputModel.direct_input_list.splice(index, 1, itemData);
    } else {
      this.directInputModel.direct_input_list.splice(index, 1);
    }
  }
  onClicReady(){
    this.isLoading = true;
    this.isLoaded = true;
    const path = apiPath.POINT_INDIVIDUAL_READY;
    const data = this.directInputModel.getLoadData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.transactionModel.setTransactionData(resultData.body);
      this.isLoading = false;
    }, (failed) => {
      this.isLoading = false;
      this.isLoaded = false;
      store.dispatch('commonToast/fetchToastStart', failed.msg || '로드에 실패했습니다');
    })
  }

  // 엑셀 업로드
  loadValidatedResult(){
    return pageValidated(this.loadValid, this.excelLoadModel);
  }


  onClickLoad(){
    if (this.isLoaded) return;
    this.postPointLoad();
  }

  onClickReset(){
    this.isLoaded = false;
    this.isConfirming = false;
    this.isConfirmed = false;
    if(this.tabData.value  === 'DIRECT_INPUT'){
      this.directInputModel = new PointIndividualDirectInputModel();
    }else if(this.tabData.value  === 'EXCEL_UPLOAD'){
      this.excelLoadModel = new LoadModel();
    }
    this.transactionModel = new TransactionModel();
  }

  onClickReward(){
    if(this.isConfirming) return;
    this.postPointConfirm();
  }

  onClickDelete(){
    const requestDelete = () => {
      this.deleteMemberDetail();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `직원을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  // 엑셀 로드
  postPointLoad(){
    this.isLoading = true;
    this.isLoaded = true;
    const path = apiPath.POINT_INDIVIDUAL_LOAD;
    const data = this.excelLoadModel.getLoadData('A2', 'C', 1);
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      console.log(resultData);
      this.transactionModel.setTransactionData(resultData.body);
      this.isLoading = false;
    }, (failed) => {
      this.isLoading = false;
      this.isLoaded = false;
      store.dispatch('commonToast/fetchToastStart', failed.msg || '로드에 실패했습니다');
    })
  }
  // 실행
  postPointConfirm(){
    this.isLoading = true;
    this.isConfirming = true;
    const id = this.transactionModel.getConfirmDataId();
    const path = apiPath.POINT_INDIVIDUAL_CONFIRM.format(id);
    POST_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.isConfirming = false;
      this.isConfirmed = true;
      this.transactionModel.setConfirmData(resultData.body);
      store.dispatch('commonToast/fetchToastStart', '포인트가 지급되었습니다');
      this.isLoading = false;
    }, (failed) => {
      this.isLoading = false;
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}